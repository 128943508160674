<template>
    <div class="statistics-flow">
        <!--<el-row class="table-search">-->
        <!--<el-col class="kuaijieriqi">-->
        <!--<el-radio-group v-model="checkedRadio" @change="radioChange" size="medium">-->
        <!--<el-radio-button label="0">今天</el-radio-button>-->
        <!--<el-radio-button label="1">昨天</el-radio-button>-->
        <!--<el-radio-button label="7">近7日</el-radio-button>-->
        <!--<el-radio-button label="30">近30日</el-radio-button>-->
        <!--</el-radio-group>-->
        <!--</el-col>-->
        <!--<el-col :span="14">-->
        <!--<el-form ref="searchForm" size="medium" :inline="true" :model="searchCondition"-->
        <!--class="demo-form-inline">-->
        <!--<el-form-item label="选择时间">-->
        <!--<el-date-picker-->
        <!--v-model="searchCondition.time_value"-->
        <!--type="daterange"-->
        <!--align="right"-->
        <!--unlink-panels-->
        <!--range-separator="至"-->
        <!--start-placeholder="开始日期"-->
        <!--end-placeholder="结束日期"-->
        <!--value-format="yyyy-MM-dd">-->
        <!--</el-date-picker>-->
        <!--</el-form-item>-->
        <!--<el-form-item>-->
        <!--<el-button type="primary" @click="searchButton" :loading='loadingStatus'>查询</el-button>-->
        <!--</el-form-item>-->
        <!--</el-form>-->
        <!--</el-col>-->
        <!--</el-row>-->
        <el-row id="echarts-flow-data">
            <el-col :span="12" style="margin-top: 50px">
                <template>
                    <el-table
                            :data="tableData"
                            border
                            style="width: 100%">
                        <el-table-column
                                v-for="(item, index) in columnList"
                                :key="index"
                                :prop="item.value"
                                :label="item.label"
                                :width="item.width">
                        </el-table-column>
                    </el-table>
                </template>
            </el-col>
            <el-col :span="12">
                <div ref="myEchart" style="width: 700px; height: 600px"></div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    import echarts from 'echarts'
    import '../../../node_modules/echarts/map/js/china.js' // 引入中国地图数据
    import elementResizeDetector from 'element-resize-detector'
    import {mapGetters} from 'vuex'

    export default {
        components: {
            echarts,
            elementResizeDetector
        },

        name: "echarts",
        props: ["userJson"],
        data() {
            return {
                chart: null,
                checkedRadio: '1',
                searchCondition: {},
                tableData: [
                    {
                        order: "1",
                        area: "北京",
                        num: "10",
                    },
                    {
                        order: "2",
                        area: "上海",
                        num: "10",
                    },
                    {
                        order: "3",
                        area: "天津",
                        num: "10",
                    },
                    {
                        order: "4",
                        area: "广东",
                        num: "10",
                    },
                    {
                        order: "5",
                        area: "福建",
                        num: "10",
                    },

                ],
                columnList: [
                    {
                        label: "排名",
                        value: "order"
                    },
                    {
                        label: "地区",
                        value: "area"
                    },
                    {
                        label: "人数",
                        value: "num"
                    },
                ],

                userData: [{
                    "name": "北京",
                    "value": 599
                }, {
                    "name": "上海",
                    "value": 142
                }, {
                    "name": "黑龙江",
                    "value": 44
                }, {
                    "name": "深圳",
                    "value": 92
                }, {
                    "name": "湖北",
                    "value": 810
                }, {
                    "name": "四川",
                    "value": 453
                }]
            };
        },
        mounted() {
            this.chinaConfigure();
        },
        beforeDestroy() {
            if (!this.chart) {
                return;
            }
            this.chart.dispose();
            this.chart = null;
        },
        computed: {
            ...mapGetters(['loadingStatus'])
        },
        watch: {},
        methods: {
            searchButton() {

            },

            radioChange(value) {

            },

            chinaConfigure() {
                let myChart = echarts.init(this.$refs.myEchart); //这里是为了获得容器所在位置
                window.onresize = myChart.resize;
                myChart.setOption({ // 进行相关配置
                    tooltip: {}, // 鼠标移到图里面的浮动提示框
                    dataRange: {
                        show: false,
                        min: 0,
                        max: 1000,
                        text: ['High', 'Low'],
                        realtime: true,
                        calculable: true,
                        color: ['#6D5BE2', '#99D7D6', '#C9EBFE']
                    },
                    geo: { // 这个是重点配置区
                        map: 'china', // 表示中国地图
                        roam: true,
                        label: {
                            normal: {
                                show: true, // 是否显示对应地名
                                textStyle: {
                                    color: 'rgba(0,0,0,0.4)'
                                }
                            }
                        },
                        itemStyle: {
                            normal: {
                                borderColor: 'rgba(0, 0, 0, 0.2)'
                            },
                            emphasis: {
                                areaColor: null,
                                shadowOffsetX: 0,
                                shadowOffsetY: 0,
                                shadowBlur: 20,
                                borderWidth: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    },
                    series: [{
                        type: 'scatter',
                        coordinateSystem: 'geo' // 对应上方配置
                    },
                        {
                            name: '用户人数', // 浮动框的标题
                            type: 'map',
                            geoIndex: 0,
                            data: this.userData
                        }
                    ]
                })
            }
        }
    }
</script>

<style lang="stylus">
    .kuaijieriqi
        width 320px

    #main
        width 100%
        height 600px
</style>
