<template>
    <div id="histogram" style="width:600px; height: 400px"></div>
</template>

<script>
    export default {
        name: "EchartsHistogram",
        data(){
            return{

            }
        },

        mounted(){
            this.initHistogram();
        },

        methods:{
            initHistogram(){
                let echarts = require('echarts/lib/echarts');
                require('echarts/lib/chart/bar');
                // 以下的组件按需引入
                require('echarts/lib/component/tooltip');   // tooltip组件
                require('echarts/lib/component/title') ;  //  title组件
                require('echarts/lib/component/legend');

                let myChart = echarts.init(document.getElementById('histogram'));

               let option = {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow'
                        }
                    },
                    legend: {
                        data: ['2011年', '2012年'],
                        bottom:0
                    },
                    grid: {
                        left: '',
                        right: '20%',
                        bottom: '10%',
                        containLabel: true
                    },
                    xAxis: {
                        type: 'value',
                        boundaryGap: [0, 0.01]
                    },
                    yAxis: {
                        type: 'category',
                        data: ['巴西', '印尼', '美国', '印度', '中国', '世界人口(万)']
                    },
                    series: [
                        {
                            name: '2011年',
                            type: 'bar',
                            data: [18203, 23489, 29034, 104970, 131744, 630230]
                        },
                        {
                            name: '2012年',
                            type: 'bar',
                            data: [19325, 23438, 31000, 121594, 134141, 681807]
                        }
                    ]
                };
                myChart.setOption(option);
            },
        }
    }
</script>

<style  scoped>

</style>