<template>
    <div id="main" style="width:1000px; height: 400px"></div>
</template>

<script>

    export default {
        name: "echartsLine",
        data(){
            return{

            }
        },

        mounted(){
            this.initLine();
        },

        methods:{
            initLine(){
                let echarts = require('echarts/lib/echarts');
                require('echarts/lib/chart/line');
                // 以下的组件按需引入
                require('echarts/lib/component/tooltip');   // tooltip组件
                require('echarts/lib/component/title') ;  //  title组件
                require('echarts/lib/component/legend');

                let myChart = echarts.init(document.getElementById('main'));
                let option = {
                    title: {
                        text: '用户练习概况',
                        textStyle: {
                            fontSize: 18,
                            fontColor:"#333",
                            fontStyle: 'normal',
                            fontWeight: 'normal',
                        }
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            label: {
                                backgroundColor: '#6a7985'
                            }
                        }
                    },
                    legend: {
                        data: ['邮件营销', '联盟广告', '视频广告', '直接访问', '搜索引擎'],
                        bottom: 0,
                    },
                    toolbox: {
                        feature: {
                            saveAsImage: {}
                        }
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '10%',
                        containLabel: true,
                    },
                    xAxis: [
                        {
                            type: 'category',
                            boundaryGap: false,
                            data: ['周一', '周二', '周三', '周四', '周五', '周六', '周日']
                        }
                    ],
                    yAxis: [
                        {
                            type: 'value'
                        }
                    ],
                    series: [
                        {
                            name: '邮件营销',
                            type: 'line',
                            stack: '总量',
                            areaStyle: {},
                            data: [120, 132, 101, 134, 90, 230, 210]
                        },
                        {
                            name: '联盟广告',
                            type: 'line',
                            stack: '总量',
                            areaStyle: {},
                            data: [220, 182, 191, 234, 290, 330, 310]
                        },
                        {
                            name: '视频广告',
                            type: 'line',
                            stack: '总量',
                            areaStyle: {},
                            data: [150, 232, 201, 154, 190, 330, 410]
                        },
                        {
                            name: '直接访问',
                            type: 'line',
                            stack: '总量',
                            areaStyle: {},
                            data: [320, 332, 301, 334, 390, 330, 320]
                        },
                        {
                            name: '搜索引擎',
                            type: 'line',
                            stack: '总量',
                            label: {
                                normal: {
                                    show: true,
                                    position: 'top'
                                }
                            },
                            areaStyle: {},
                            data: [820, 932, 901, 934, 1290, 1330, 1320]
                        }
                    ]
                };

                // 使用刚指定的配置项和数据显示图表。
                myChart.setOption(option);

            }
        }
    }
</script>

<style scoped>

</style>