<template>
    <el-col class="el-lg-4 el-col-md-6 el-col-sm-24" >
        <el-card class="card-box" shadow="never">
            <div class="flex-center">
                <div>
                    <img src="../assets/note.png" width="50" alt="" v-if="inputData.type === 'note'"/>
                    <img src="../assets/dianment.png" width="50" alt="" v-else-if="inputData.type === 'vip'" />
                    <img src="../assets/defalut.png" width="50" alt="" v-else-if="inputData.type === 'user'"/>
                    <img src="../assets/user.png" width="50" alt="" v-else-if="inputData.type === 'new_user'"/>
                </div>
                <div class="flex-middle">
                    <span class="font-size-14 font-color-33">{{ inputData.title }}</span>
                    <label class="font-size-30 font-color-33" style="margin-top: 12px">{{ inputData.value }}</label>
                </div>
            </div>
        </el-card>
    </el-col>
</template>

<script>
    export default {
        name: "HomeCard",
        props:{
            inputData:{
                type:Object,
                default:()=>{}
            }
        }
    }
</script>

<style lang="stylus" scoped>
    .flex-center
        display flex
        justify-content space-between
        align-items center
        height 110px

    .flex-middle
        display flex
        flex-direction column
        text-align right

    .card-box
        min-height 146px

    .font-size-30
        font-size 30px

    .font-size-14
        font-size 14px

    .font-color-33
        color #333
</style>