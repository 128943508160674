<template>
  <div class="mars-ape-layout-header" :style="{width: isCollapse ? 'calc(100vw - 80px)' : 'calc(100vw - 256px)'}">
    <div class="header-left">
      <i class="iconfont header-index-trigger" :class="[isCollapse?'icon-menu-fold':'icon-menu-unfold']"
         @click="handleClick"></i>
    </div>
    <div class="header-middle">

    </div>
    <div class="header-right">
      <span class="right-notice">
         <el-tooltip v-if="bankChoice.name" class="item" effect="dark" content="点击切换题库" placement="left">
          <el-tag @click="onChangeQuestionBankClick">{{ bankChoice.name }}</el-tag>
         </el-tooltip>
        <el-tooltip v-else class="item" effect="dark" content="点击切换题库" placement="left">
          <el-tag @click="onChangeQuestionBankClick">选择题库</el-tag>
         </el-tooltip>
      </span>
      <el-dropdown @command="handleCommand">
        <span class="right-account">
          <span class="right-account-avatar">
            <img src="@/assets/avatar.png" alt="avatar">
          </span>
          <span class="right-account-name">{{ userInfo['nickname'] }}</span>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="setting">
            <i class="iconfont icon-touxiang"></i><span>修改密码</span>
          </el-dropdown-item>
          <el-dropdown-item divided command="logout"><i
              class="iconfont icon-tuichudenglu2"></i><span>退出登录</span></el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <el-dialog :destroy-on-close="true" :visible.sync="isShowDialog" :close-on-click-modal="false" width="20%" title="切换题库"
               :append-to-body="true">
      <div class="input-suffix">
        当前题库：
        <el-select v-model="bankChoice.bank_id" filterable placeholder="请选择" @change="handleQuestionBankSelect">
          <el-option
              v-for="item in questionBankList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="onDialogCancel">取 消</el-button>
        <el-button type="primary" @click="onModifyDialogConfirm">确定</el-button>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="isShowModifyDialog" :close-on-click-modal="false" width="30%" title="修改密码"
               :append-to-body="true" :destroy-on-close="true">
      <div class="input-suffix">
        <el-form :model="passwordChangeForm" :rules="pwdRules" ref="changePwd" label-position="right"
                 label-width="80px">
          <el-form-item label="旧密码" prop="password">
            <el-input v-model="passwordChangeForm.password" type="password"
                      onkeyup="value=value.replace(/[\u4E00-\u9FA5]/g,'')"></el-input>
          </el-form-item>
          <el-form-item label="新密码" prop="new_pwd">
            <el-input v-model="passwordChangeForm.new_pwd" type="password"
                      onkeyup="value=value.replace(/[\u4E00-\u9FA5]/g,'')"></el-input>
          </el-form-item>
          <el-form-item label="确认密码" prop="confirm_pwd">
            <el-input v-model="passwordChangeForm.confirm_pwd" type="password"
                      onkeyup="value=value.replace(/[\u4E00-\u9FA5]/g,'')"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="onPassworkDialogCancel">取 消</el-button>
        <el-button type="primary" @click="onModifyPassworkConfirm">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'
import ChangeQuestionBank from "@/components/ChangeQuestionBank";

export default {
  components: {ChangeQuestionBank},
  data() {
    return {
      isShowModifyDialog: false,
      isShowDialog: false,
      bankChoice: {},
      curBankId: "",
      questionBankList: [],
      options: [],
      value: '',
      passwordChangeForm: {},
      pwdRules: {
        password: [
          {required: true, message: '请输入原密码', trigger: 'blur'}
        ],
        new_pwd: [
          {required: true, min: 8, max: 16, message: '长度在 8 到 16 个字符', trigger: 'blur'}
        ],
        confirm_pwd: [
          {required: true, validator: this.vaildateConfirmPass, trigger: 'blur'}
        ]
      },
    }
  },
  computed: {
    ...mapGetters(['isCollapse', 'userInfo'])
  },
  watch: {
  },
  mounted() {
    let userInfo = JSON.parse(localStorage.getItem('user_info'));
    this.handleUserInfo(userInfo);
    this.initQuestionSysBankList();

    console.log(this.qbId)
  },
  methods: {
    ...mapMutations([
      'handleUserInfo', 'storeUserBankChoice'
    ]),
    // 左侧菜单的展开折叠
    handleClick() {
      this.$store.commit('changeCollapse')
    },
    // 处理下拉菜单指令
    handleCommand(command) {
      if (command === 'logout') {
        this.handleLogout()
      } else {
        this.onModityPassworkClick()
      }
    },
    // 处理退出
    async handleLogout() {
      this.$api.submitLogout();
      localStorage.removeItem('user_info');
      this.$message.success('退出成功！');
      this.$router.push('/login')
    },

    //对话框取消点击
    onDialogCancel() {
      this.isShowDialog = false;
    },

    //对话框确认点击
    onModifyDialogConfirm() {
      if (!this.curBankId) {
        this.$message("请选择题库");
        return;
      }

      let qbName = this.getQuestionBankName();

      this.$confirm('题库将切换到：[' + qbName + ']', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        this.isShowDialog = false;
        this.putBankChoiceToStore(this.curBankId);

        this.curBankId = "";
        this.qbName = qbName;

      }).catch(() => {
      })

    },

    handleQuestionBankSelect(item) {
      this.curBankId = item;
    },

    onChangeQuestionBankClick() {
      this.initQuestionSysBankList()
      this.isShowDialog = true;
    },

    //获取题库
    async initQuestionSysBankList() {
      let {list} = await this.$api.getQuestionBankSysList();
      let {info} = await this.$api.getChoseQuestionBank()
      this.bankChoice = info
      this.$nextTick(() => {
        let questionList = [];
        for (let key in list) {
          let item = list[key];
          let object = {
            "label": item.name,
            "value": item.id,
          };
          questionList.push(object);
        }
        this.questionBankList = questionList;

      });
    },

    //获取题库名称
    getQuestionBankName() {
      if (!this.curBankId) {
        return ""
      }

      if (this.questionBankList.length <= 0) {
        return ""
      }

      for (let bank of this.questionBankList) {
        if (bank.value === this.curBankId) {
          return bank.label;
        }
      }
    },

    async putBankChoiceToStore(bankId) {
      let param = {
        "qb_id": bankId
      };
      let {info} = await this.$api.chooseQuestionBank(param);
      if (info) {
        this.storeUserBankChoice(info)
        this.initQuestionSysBankList()
      }
    },

    onModityPassworkClick() {
      this.isShowModifyDialog = true
    },

    vaildateConfirmPass(rule, value, callback) {

      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value != this.passwordChangeForm.new_pwd) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    },

    onModifyPassworkConfirm() {
      let password = this.passwordChangeForm.password;
      let newPassword = this.passwordChangeForm.new_pwd;
      // let confirmPwd = this.passwordChangeForm.confirm_pwd;
      this.$refs['changePwd'].validate(async (valid) => {
        if (valid) {
          let params = {
            new_password: newPassword,
            password: password
          };
          let info = await this.$api.updateUserPwd(params);

          if (info) {
            this.$message.success('修改成功');
            this.isShowModifyDialog = false

            this.passwordChangeForm = {
              password: '',
              new_pwd: '',
              confirm_pwd: ''
            };

            this.$api.submitLogout();
            localStorage.removeItem('user_info');
            this.$message.success('退出成功！');
            this.$router.push('/login')
          }

        } else {
          this.$message.error('请填写必填项');
        }
      });

    },

    onPassworkDialogCancel() {
      this.isShowModifyDialog = false
      this.passwordChangeForm = {
        password: '',
        new_pwd: '',
        confirm_pwd: ''
      };
    }
  },

}
</script>

<style lang="stylus" scoped>
.mars-ape-layout-header .iconfont
  display inline-block
  box-sizing border-box
  font-size 24px

.mars-ape-layout-header
  height 64px
  background #fff
  box-shadow 0 1px 4px rgba(0, 21, 41, .08)
  position fixed
  top: 0
  z-index: 100
  transitionall .3s
  overflow hidden

.mars-ape-layout-header > .header-left, .mars-ape-layout-header > .header-middle
  float left
  color #595959

.mars-ape-layout-header > .header-right
  float right
  line-height 64px
  margin-right 20px
  overflow hidden

.mars-ape-layout-header > .header-left > .header-index-trigger
  font-size 20px
  height 64px
  cursor pointer
  transition all .3s, padding 0s
  padding 22px 24px

i.header-index-trigger:hover
  background rgba(0, 0, 0, .025)

.header-right .right-account, .header-right .right-notice
  padding 0 12px
  display inline-block
  transition all .3s
  cursor pointer
  color: rgba(0, 0, 0, .65)

.right-notice .el-badge
  line-height 24px

.right-account-avatar > img
  width 100%
  display block

.right-account-avatar
  width 24px
  line-height 24px
  border-radius 50%
  display inline-block
  margin 20px 8px 20px 0
  vertical-align top

.right-account-name
  font-size 14px

.right-notice:hover, .right-account:hover
  background rgba(0, 0, 0, .025)

.el-dropdown-menu__item
  padding 5px 12px
  line-height 22px
  font-weight 400
  color rgba(0, 0, 0, 0.65)

.el-dropdown-menu__item > .iconfont
  margin-right 8px

.el-dropdown-menu
  padding 4px 0
  margin 0px

.el-dropdown-menu__item--divided
  padding-top 0px
  line-height 28px

.el-dropdown-menu__item--divided::before
  margin 0 -12px

.el-dropdown-menu.el-popper
  min-width 160px
  top 56px !important

.el-popper .popper__arrow
  border-width 0px

.el-popper .popper__arrow::after
  border-width 0px

.input-suffix
  margin-top 10px

///deep/ .el-input__inner
//  border none
//  background-color rgba(0, 153, 255, 0.1)
//  border-radius 50px
//  color #0099ff
//
///deep/ .el-select__caret.el-input__icon.el-icon-arrow-up
//  color #0099ff
</style>
