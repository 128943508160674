<template>
    <div class="mars-ape-sider" :class="{'mars-ape-sider-collapse':isCollapse}">
        <div class="sider-logo">
            <a href="/">
                <img src="@/assets/logo.png" alt="logo">
                <h1>易学法后台管理</h1>
            </a>
        </div>
        <el-menu class="sider-menu"
                 :default-active="routePath"
                 :unique-opened="true"
                 active-text-color="#ffffff"
                 :collapse="isCollapse">
            <el-submenu
                    v-for="(v, k) in menuList"
                    :key="k"
                    :index="v['id'].toString()">
                <template slot="title">
                        <i  :class="[v['icon'],isCollapse?'is-collapse':'']"></i>
                    <span>{{v['display_name']}}</span>
                </template>
                <div class="sider-menu-sub">
                    <el-menu-item
                            v-for="(v, k) in v['children']"
                            :key="k"
                            :index="v['url']">
                        <router-link :to="v['url']">{{v['display_name']}}</router-link>
                    </el-menu-item>
                </div>
            </el-submenu>
        </el-menu>
    </div>
</template>

<script>
    import {mapGetters, mapMutations} from 'vuex'

    export default {
        computed: {
            ...mapGetters(['isCollapse', 'menuList', 'routePath'])
        },
        methods: {
            ...mapMutations([
                'handleMenuList',
                'handleRoutePath',
                'handleUserPermissions',
            ])
        },
        async created() {
            let userMenuList = {
                "list": [
                    {
                        "id": 26,
                        "system_permission_id": 0,
                        "parent_id": 0,
                        "display_name": "用户管理",
                        "icon": "icon-fenlei3",
                        "url": "/user",
                        "order": 0,
                        "permission_name": "category_list",
                        "depth": 0,
                        "children": [
                            {
                                "id": 36,
                                "system_permission_id": 18,
                                "parent_id": 3,
                                "display_name": "用户列表",
                                "icon": null,
                                "url": "/user_list",
                                "order": 1,
                                "permission_name": "category_list",
                                "depth": 2
                            }
                        ]
                    },
                    {
                        "id": 25,
                        "system_permission_id": 0,
                        "parent_id": 0,
                        "display_name": "题库管理",
                        "icon": "icon-fenlei3",
                        "url": "/question_bank",
                        "order": 0,
                        "permission_name": null,
                        "depth": 0,
                        "children": [
                            {
                                "id": 29,
                                "system_permission_id": 18,
                                "parent_id": 25,
                                "display_name": "题库",
                                "icon": null,
                                "url": "/question_bank",
                                "order": 0,
                                "permission_name": "category_list",
                                "depth": 1
                            },
                            {
                                "id": 30,
                                "system_permission_id": 18,
                                "parent_id": 25,
                                "display_name": "题目管理",
                                "icon": null,
                                "url": "/question_bank_question",
                                "order": 1,
                                "permission_name": "category_list",
                                "depth": 2
                            }
                        ]
                    },
                    {
                        "id": 1,
                        "system_permission_id": 0,
                        "parent_id": 0,
                        "display_name": "轮播图管理",
                        "icon": "icon-fenlei3",
                        "url": "/carousel",
                        "order": 0,
                        "permission_name": "category_list",
                        "depth": 0,
                        "children": [
                            {
                                "id": 31,
                                "system_permission_id": 18,
                                "parent_id": 1,
                                "display_name": "轮播图列表",
                                "icon": null,
                                "url": "/carousel_list",
                                "order": 1,
                                "permission_name": "category_list",
                                "depth": 2
                            }
                        ]
                    },
                    {
                        "id": 2,
                        "system_permission_id": 0,
                        "parent_id": 0,
                        "display_name": "文章管理",
                        "icon": "icon-fenlei3",
                        "url": "/article",
                        "order": 0,
                        "permission_name": "category_list",
                        "depth": 0,
                        "children": [
                            {
                                "id": 31,
                                "system_permission_id": 18,
                                "parent_id": 2,
                                "display_name": "文章列表",
                                "icon": null,
                                "url": "/article_list",
                                "order": 1,
                                "permission_name": "category_list",
                                "depth": 2
                            }
                        ]
                    },
                    {
                        "id": 3,
                        "system_permission_id": 0,
                        "parent_id": 0,
                        "display_name": "练习管理",
                        "icon": "icon-fenlei3",
                        "url": "/question_type",
                        "order": 0,
                        "permission_name": "category_list",
                        "depth": 0,
                        "children": [
                            {
                                "id": 32,
                                "system_permission_id": 18,
                                "parent_id": 3,
                                "display_name": "题型练习",
                                "icon": null,
                                "url": "/question_type",
                                "order": 1,
                                "permission_name": "category_list",
                                "depth": 2
                            },
                            {
                                "id": 37,
                                "system_permission_id": 18,
                                "parent_id": 3,
                                "display_name": "章节练习",
                                "icon": null,
                                "url": "/chapter",
                                "order": 2,
                                "permission_name": "category_list",
                                "depth": 2
                            },
                            // {
                            //     "id": 42,
                            //     "system_permission_id": 18,
                            //     "parent_id": 3,
                            //     "display_name": "每日练习",
                            //     "icon": null,
                            //     "url": "/daily_practice",
                            //     "order": 2,
                            //     "permission_name": "category_list",
                            //     "depth": 2
                            // },
                        ]
                    },
                    {
                        "id": 4,
                        "system_permission_id": 0,
                        "parent_id": 0,
                        "display_name": "试卷管理",
                        "icon": "icon-fenlei3",
                        "url": "/test_paper",
                        "order": 0,
                        "permission_name": "category_list",
                        "depth": 0,
                        "children": [
                            {
                                "id": 33,
                                "system_permission_id": 18,
                                "parent_id": 3,
                                "display_name": "模拟试卷",
                                "icon": null,
                                "url": "/test_paper",
                                "order": 1,
                                "permission_name": "category_list",
                                "depth": 2
                            },
                            {
                                "id": 34,
                                "system_permission_id": 18,
                                "parent_id": 3,
                                "display_name": "历史真题",
                                "icon": null,
                                "url": "/history_real",
                                "order": 2,
                                "permission_name": "category_list",
                                "depth": 2
                            }
                        ]
                    },
                    {
                        "id": 5,
                        "system_permission_id": 0,
                        "parent_id": 0,
                        "display_name": "专题管理",
                        "icon": "icon-fenlei3",
                        "url": "/special_topic",
                        "order": 0,
                        "permission_name": "category_list",
                        "depth": 0,
                        "children": [
                            {
                                "id": 35,
                                "system_permission_id": 18,
                                "parent_id": 3,
                                "display_name": "专题列表",
                                "icon": null,
                                "url": "/special_topic",
                                "order": 1,
                                "permission_name": "category_list",
                                "depth": 2
                            }
                        ]
                    },
                    {
                        "id": 6,
                        "system_permission_id": 0,
                        "parent_id": 0,
                        "display_name": "订单管理",
                        "icon": "icon-fenlei3",
                        "url": "/order",
                        "order": 0,
                        "permission_name": "category_list",
                        "depth": 0,
                        "children": [
                            {
                                "id": 37,
                                "system_permission_id": 18,
                                "parent_id": 3,
                                "display_name": "订单列表",
                                "icon": null,
                                "url": "/order",
                                "order": 1,
                                "permission_name": "category_list",
                                "depth": 2
                            }
                        ]
                    },
                    {
                        "id": 7,
                        "system_permission_id": 0,
                        "parent_id": 0,
                        "display_name": "套餐管理",
                        "icon": "icon-fenlei3",
                        "url": "/meal",
                        "order": 0,
                        "permission_name": "category_list",
                        "depth": 0,
                        "children": [
                            {
                                "id": 37,
                                "system_permission_id": 18,
                                "parent_id": 3,
                                "display_name": "套餐列表",
                                "icon": null,
                                "url": "/meal",
                                "order": 1,
                                "permission_name": "category_list",
                                "depth": 2
                            }
                        ]
                    },
                    {
                        "id": 8,
                        "system_permission_id": 0,
                        "parent_id": 0,
                        "display_name": "导入计划管理",
                        "icon": "icon-fenlei3",
                        "url": "/import_file",
                        "order": 0,
                        "permission_name": "category_list",
                        "depth": 0,
                        "children": [
                            {
                                "id": 37,
                                "system_permission_id": 18,
                                "parent_id": 3,
                                "display_name": "导入计划",
                                "icon": null,
                                "url": "/import_file_list",
                                "order": 1,
                                "permission_name": "category_list",
                                "depth": 2
                            }
                        ]
                    },
                    {
                        "id": 9,
                        "system_permission_id": 0,
                        "parent_id": 0,
                        "display_name": "系统管理",
                        "icon": "icon-fenlei3",
                        "url": "/system",
                        "order": 0,
                        "permission_name": "category_list",
                        "depth": 0,
                        "children": [
                            {
                                "id": 38,
                                "system_permission_id": 18,
                                "parent_id": 3,
                                "display_name": "权限管理",
                                "icon": null,
                                "url": "/permissions_list",
                                "order": 1,
                                "permission_name": "category_list",
                                "depth": 2
                            },
                            {
                                "id": 39,
                                "system_permission_id": 18,
                                "parent_id": 3,
                                "display_name": "菜单管理",
                                "icon": null,
                                "url": "/menu_list",
                                "order": 1,
                                "permission_name": "category_list",
                                "depth": 2
                            },
                            {
                                "id": 40,
                                "system_permission_id": 18,
                                "parent_id": 3,
                                "display_name": "角色管理",
                                "icon": null,
                                "url": "/roles_list",
                                "order": 1,
                                "permission_name": "category_list",
                                "depth": 2
                            },
                            {
                                "id": 42,
                                "system_permission_id": 18,
                                "parent_id": 3,
                                "display_name": "用户管理",
                                "icon": null,
                                "url": "/user_manner",
                                "order": 1,
                                "permission_name": "category_list",
                                "depth": 2
                            },
                        ]
                    },

                    {
                        "id": 10,
                        "system_permission_id": 0,
                        "parent_id": 0,
                        "display_name": "用户反馈",
                        "icon": "icon-fenlei3",
                        "url": "/feedback",
                        "order": 0,
                        "permission_name": "category_list",
                        "depth": 0,
                        "children": [
                            {
                                "id": 37,
                                "system_permission_id": 18,
                                "parent_id": 3,
                                "display_name": "反馈列表",
                                "icon": null,
                                "url": "/feedback",
                                "order": 1,
                                "permission_name": "category_list",
                                "depth": 2
                            }, {
                                "id": 38,
                                "system_permission_id": 18,
                                "parent_id": 3,
                                "display_name": "题目纠错",
                                "icon": null,
                                "url": "/correction",
                                "order": 1,
                                "permission_name": "category_list",
                                "depth": 2
                            }
                        ]
                    },

                    {
                        "id": 11,
                        "system_permission_id": 0,
                        "parent_id": 0,
                        "display_name": "客服管理",
                        "icon": "icon-fenlei3",
                        "url": "/customer",
                        "order": 0,
                        "permission_name": "category_list",
                        "depth": 0,
                        "children": [
                            {
                                "id": 41,
                                "system_permission_id": 18,
                                "parent_id": 3,
                                "display_name": "客服列表",
                                "icon": null,
                                "url": "/customer",
                                "order": 1,
                                "permission_name": "category_list",
                                "depth": 2
                            }
                        ]
                    },

                    {
                        "id": 12,
                        "system_permission_id": 0,
                        "parent_id": 0,
                        "display_name": "考试指南管理",
                        "icon": "icon-fenlei3",
                        "url": "/exam_guide_list",
                        "order": 0,
                        "permission_name": "category_list",
                        "depth": 0,
                        "children": [
                            {
                                "id": 42,
                                "system_permission_id": 18,
                                "parent_id": 3,
                                "display_name": "考试指南列表",
                                "icon": null,
                                "url": "/exam_guide_list",
                                "order": 1,
                                "permission_name": "category_list",
                                "depth": 2
                            }
                        ]
                    },

                ]
            };
            userMenuList = await this.$api.getUserMenuList();
            let userPermissionsList = {
                list: [
                    "category_list",

                    //题库
                    "question_bank_create",
                    "question_bank_edit",
                    "question_bank_delete",
                    "question_bank_import",

                    //题目
                    "question_bank_question_view",
                    "question_bank_question_create",
                    "question_bank_question_edit",
                    "question_bank_question_delete",

                    //题型
                    "exercise_question_type_view",
                    "exercise_question_type_create",
                    "exercise_question_type_edit",
                    "exercise_question_type_delete",
                    "exercise_question_type_add",

                    //章节练习
                    "exercise_chapter_view",
                    "exercise_chapter_create",
                    "exercise_chapter_edit",
                    "exercise_chapter_delete",
                    "exercise_chapter_add",

                    //文章
                    "article_create",
                    "article_attachment",
                    "article_edit",
                    "article_delete",

                    //轮播图
                    "carousel_view",
                    "carousel_create",
                    "carousel_delete",
                    "carousel_edit",

                    //模拟试卷
                    "test_test_paper_view",
                    "test_test_paper_create",
                    "test_test_paper_delete",
                    "test_test_paper_edit",
                    "test_test_paper_add",

                    //历史真题
                    "test_history_real_paper_view",
                    "test_history_real_paper_add",
                    "test_history_real_paper_create",
                    "test_history_real_paper_delete",
                    "test_history_real_paper_edit",

                    //专题管理权限
                    "special_topic_view",
                    "special_topic_add",
                    "special_topic_create",
                    "special_topic_delete",
                    "special_topic_edit",

                    //专题分类权限
                    "special_topic_category_view",
                    "special_topic_category_add",
                    "special_topic_category_create",
                    "special_topic_category_delete",
                    "special_topic_category_update",

                    //专题分类文章
                    "special_topic_category_article_view",
                    "special_topic_category_article_add",
                    "special_topic_category_article_create",
                    "special_topic_category_article_delete",
                    "special_topic_category_article_edit",

                    //用户
                    "user_view",
                    "user_up",//升级用户
                    "user_import",

                    //订单
                    "order_view",

                    //套餐
                    "package_create",
                    "package_view",
                    "package_edit",
                    "package_delete",

                    //权限
                    "permissions_create",
                    "permissions_edit",
                    "permissions_delete",

                     //角色
                    "roles_create",
                    "roles_edit",
                    "roles_permissions",
                    "roles_users",
                    "roles_delete",

                    //菜单
                    "menu_create",
                    "menu_edit",
                    "menu_delete",

                    //反馈
                    "feedback_edit",
                    "feedback_create",
                    "feedback_edit",
                    "feedback_delete",

                    //纠错
                    "correction_edit",
                    "correction_edit_status",

                    //客服
                    "customer_create",
                    "customer_edit",
                    "customer_delete",

                    //用户管理
                    "sys_user_manage_create",
                    "sys_user_manage_edit",
                    "sys_user_manage_delete",

                    //每日练习
                    "daily_practice_add",
                    "daily_practice_create",
                    "daily_practice_edit",
                    "daily_practice_delete",
                    "daily_practice_copy",
                    "daily_practice_article",

                    //考试指南
                    "exam_guide_create",
                    "exam_guide_edit",
                    "exam_guide_delete",
                    "exam_guide_add"
                ]
            };
            userPermissionsList = await this.$api.getUserPermissionsList();
            this.handleMenuList(userMenuList['list']);
            this.handleUserPermissions(userPermissionsList['list']);
            if (Object.keys(this.$route.params).length != 0 && this.$route.matched[1]) {
                this.handleRoutePath(this.$route.matched[1].path)
            } else {
                this.handleRoutePath(this.$route.path)
            }
        },
    }
</script>

<style lang="stylus">
    .styleFont
        font-size 12px

    .mars-ape-sider
        position relative

    .mars-ape-sider:not(.mars-ape-sider-collapse)
        /* flex 0 0 256px */
        width 256px !important
        transition: all .3s

    .mars-ape-sider-collapse, .el-menu--collapse
        /* flex 0 0 80px */
        width 80px !important
        transition: all .3s

    /* 导航头部 */
    .sider-logo
        height 64px
        position absolute
        top 0
        left 0
        width 100%
        transition all .3s
        line-height 64px
        /*padding-left 24px*/
        text-align center
        background #002140
        overflow hidden
        z-index 99
        img
            display inline-block
            vertical-align middle
            width 32px
        h1
            color #fff
            display inline-block
            vertical-align middle
            font-size 20px
            margin 0 0 0 12px
            font-family Avenir, Helvetica Neue, Arial, Helvetica, sans-serif
            font-weight 600

    /* 导航内容 */
    .sider-menu
        padding-top 64px
        height calc(100vh - 64px)
        overflow-y auto
        width auto !important
        transition all .3s
        scrollbar-width: none

    .sider-menu::-webkit-scrollbar{
        display:none;
    }

    .el-menu
        border-right solid 0px !important
        list-style none
        position relative
        margin 0
        padding-left 0
        background-color #001529

    .sider-menu-sub
        background #000c17
        box-shadow inset 0 2px 8px rgba(0, 0, 0, .45)

    .el-submenu__title, .el-submenu__title
        color: #b8bec3

    .el-submenu__title
        padding-left 24px !important
        padding-right 34px
        margin-top 4px
        margin-bottom 4px
        width 100%
        line-height 40px
        height 40px
    .el-menu-item
        height 40px
        line-height 40px
        padding-left 48px !important
        padding-right 16px
        margin-top 4px
        margin-bottom 8px
        color: #b8bec3
        a
            color #b8bec3
            text-decoration none
            display: block

    .el-submenu [class^="iconfont"].is-collapse
        padding-left 8px
        font-size 16px

    .el-submenu [class^="iconfont"]:not(.is-collapse)
        vertical-align middle
        margin-right 10px
        display inline-block
        margin-top -23px
        height 36px
        min-width 14px
        text-align center
        font-size 14px

    .el-menu-item:hover, .el-menu-item:focus
        background-color: #000c17

    .el-menu-item:hover a, .el-menu-item:focus a
        color #ffffff

    .el-submenu__title:hover, .el-submenu__title:hover i, .el-submenu__title:focus i .el-submenu__title:focus
        background-color: #001529
        color #ffffff

    .el-submenu.is-opened .el-submenu__title, .el-submenu.is-opened .el-submenu__title > i, .el-submenu.is-active .el-submenu__title, .is-active > a, .el-submenu.is-active .el-submenu__title > i
        color #ffffff

    .el-menu-item.is-active
        background-color #179aff


    .el-submenu__title *
        vertical-align center!important

    /* 折叠后子菜单效果 */
    .el-menu--vertical
        left 84px !important

    .el-menu--popup
        border-radius 4px
        min-width 160px
</style>