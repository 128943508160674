<template>
    <el-container style="display: flex">
        <el-aside class="sidebar-menu">
            <SiderMenu/>
        </el-aside>
        <el-container class="mars-ape-layout" :style="{paddingLeft: isCollapse ? '80px' : '256px'}">
            <el-header class="mars-ape-layout-header">
                <Header/>
            </el-header>
            <el-main>
                <div v-if="isRootRoutePath">
<!--                    <home></home>-->
                </div>
                <router-view/>
            </el-main>
            <el-footer class="container-footer" :style="{left: isCollapse ? '0' : '80px'}">
                <Footer/>
            </el-footer>
        </el-container>
    </el-container>
</template>
<script>
    import SiderMenu from './SiderMenu'
    import Header from './Header'
    import Footer from './Footer'
    import Home from '@/pages/home/Index'
    import { mapGetters } from 'vuex'

    export default {
        components: {
            SiderMenu,
            Header,
            Footer,
            Home
        },
        data() {
            return {}
        },
        computed: {
            ...mapGetters(['isCollapse']),
            isRootRoutePath: function () {
                return this.$route.path == '/'
            },
        }
    }
</script>
<style lang="stylus">
    .mod-index
        border-radius 5px
        background #fff
        padding 24px
        min-height 800px
        line-height 1.5
        p
            margin 0
        h3
            margin-block-start 0
            margin-block-end 0.5em
        .xm-gt img
            height 240px
            margin-left -16px
        .jx-zz img
            height 400px
            margin-top 24px
            margin-right 24px

    .el-container
        background #f0f2f5

    .el-aside
        min-height 100vh
        position relative
        width auto !important
        z-index 10
        transitionall .3s
        background #001529

    .el-header
        height 64px !important
        padding 0px
        width 100%

    .el-main
        padding 0
        margin 24px 24px 50px
        box-sizing border-box
        overflow inherit
        flexnone !important
        overflow-y auto
        overflow-x hidden

    .main-page-content
        border-radius 5px
        background #ffffff
        padding 24px
        overflow hidden
        box-sizing border-box

    .el-footer
        height 50px !important
        display flex
        align-items center
        justify-content center
        position fixed
        bottom 0
        width 100%
        z-index 99
        background-color #f0f2f5

    .sidebar-menu
        height 100vh
        overflow-y auto
        position fixed
        top 0
        left 0
        z-index 100

</style>


