<template>
    <div id="pie" style="width:500px; height: 400px"></div>
</template>

<script>
    export default {
        name: "EhcartsPie",
        data(){
            return{

            }
        },

        mounted(){
            this.initPie();
        },

        methods:{
            initPie(){
                let echarts = require('echarts/lib/echarts');
                require('echarts/lib/chart/pie');
                // 以下的组件按需引入
                require('echarts/lib/component/tooltip');   // tooltip组件
                require('echarts/lib/component/title') ;  //  title组件
                require('echarts/lib/component/legend');

                let myChart = echarts.init(document.getElementById('pie'));

                let option = {
                    tooltip: {
                        trigger: 'item',
                        formatter: '{a} <br/>{b}: {c} ({d}%)'
                    },
                    legend: {
                        bottom: 10,
                        data: ['直接访问', '邮件营销', '联盟广告', '视频广告', '搜索引擎']
                    },
                    series: [
                        {
                            name: '访问来源',
                            type: 'pie',
                            radius: ['50%', '70%'],
                            avoidLabelOverlap: false,
                            label: {
                                show: false,
                                position: 'center',
                                normal: {
                                    formatter: '{b}  {d}%  ',
                                }
                            },
                            emphasis: {
                                label: {
                                    show: true,
                                    fontSize: '30',
                                    fontWeight: 'bold'
                                }
                            },
                            labelLine: {
                                show: true
                            },
                            data: [
                                {value: 335, name: '直接访问'},
                                {value: 310, name: '邮件营销'},
                                {value: 234, name: '联盟广告'},
                                {value: 135, name: '视频广告'},
                                {value: 1548, name: '搜索引擎'}
                            ]
                        }
                    ]
                };

                myChart.setOption(option);
            }
        }
    }
</script>

<style scoped>

</style>