<template>
    <div>
        <el-row :gutter="10">
            <el-col>
                <div v-for="item in info">
                    <card-box
                            :inputData="item"
                    ></card-box>
                </div>
            </el-col>
            <el-col style="margin-top: 20px">
                <el-col class="el-col-md-18">
                    <el-card>
                        <echarts-line></echarts-line>
                    </el-card>
                </el-col>
                <el-col class="el-col-md-6">
                    <el-card style="height: 440px">
                        <div slot="header" class="clearfix">
                            <span>快捷方式</span>
                            <el-button style="float: right; padding: 3px 0" type="text" @click="onAddShortcutClick"><i
                                    class="el-icon-plus"></i>
                            </el-button>
                        </div>

                        <el-col v-for="(item,index) in shortcutList"
                                :key="index"
                                class="el-col-md-7 card-box"
                                align="center"
                                style="height: 100px;margin-left: 12px;margin-bottom: 12px" >
                            <div class="el-col-24" @click="onJumpShortClick(item.route)">
                                <img src="@/assets/note.png" alt="" width="40">
                            </div>
                            <div class="el-col-24">{{ item.name }}</div>
                        </el-col>
                    </el-card>
                </el-col>
            </el-col>
        </el-row>

        <el-dialog :visible.sync="isShowAddShortcutDialog" title="新增快捷方式" append-to-body width="30%" :destroy-on-close="true">
            <div slot="footer" class="dialog-footer">
                <el-form :model="shortcutForm"
                         :rules="shortRule"
                         label-width="80px"
                         ref="shortcutForm"
                >
                    <el-form-item label="名称" prop="name">
                        <el-col class="el-col-lg-20 el-col-md-20">
                            <el-input v-model="shortcutForm.name" placeholder="请输入快捷方式名称"/>
                        </el-col>
                    </el-form-item>
                    <el-form-item label="路由" prop="router">
                        <el-col class="el-col-lg-20 el-col-md-20">
                            <el-input v-model="shortcutForm.router" placeholder="请输入快捷方式路由"/>
                        </el-col>
                    </el-form-item>
                </el-form>
                <el-button @click="onShortcutDialogCancel">取 消</el-button>
                <el-button type="primary" @click="onShortcutDialogConfirm">确定</el-button>
            </div>
        </el-dialog>

        <el-row style="margin-top: 12px" :gutter="12">
            <el-col class="el-col-sm-24 el-col-md-8 el-col-lg-8">
                <el-card>
                    <div class="el-col-24">
                        <el-col class="el-col-sm-4 el-col-md-18" >考试得分TOP10</el-col>
                        <el-col class="el-col-sm-20 el-col-md-6 el-col-lg-6">
                            <el-select v-model="curTime" size="small" class="z-index-999">
                                <el-option v-for="item in timeList"
                                           :key="item.value"
                                           :label="item.label"
                                           :value="item.value"
                                ></el-option>
                            </el-select>
                        </el-col>
                    </div>
                    <echarts-histogram></echarts-histogram>
                </el-card>
            </el-col>

            <el-col class="el-col-sm-24 el-col-md-8 el-col-lg-8">
                <el-card>
                    <div class="el-col-24">
                        <el-col class="el-col-sm-4 el-col-md-18" >用户支付概况</el-col>
                        <el-col class="el-col-sm-20 el-col-md-6 el-col-lg-6">
                            <el-select v-model="curTime" size="small" class="z-index-999">
                                <el-option v-for="item in timeList"
                                           :key="item.value"
                                           :label="item.label"
                                           :value="item.value"
                                ></el-option>
                            </el-select>
                        </el-col>
                    </div>
                    <echarts-histogram-column></echarts-histogram-column>
                </el-card>
            </el-col>

            <el-col class="el-col-sm-24 el-col-md-8 el-col-lg-8">
                <el-card>
                    <div class="el-col-24">
                        <el-col class="el-col-sm-4 el-col-md-18" >用户占比</el-col>
                        <el-col class="el-col-sm-20 el-col-md-6 el-col-lg-6">
                            <el-select v-model="curTime" size="small" class="z-index-999">
                                <el-option v-for="item in timeList"
                                           :key="item.value"
                                           :label="item.label"
                                           :value="item.value"
                                ></el-option>
                            </el-select>
                        </el-col>
                    </div>
                    <ehcarts-pie></ehcarts-pie>
                </el-card>
            </el-col>
        </el-row>
        <el-row  style="margin-top: 12px">
            <el-card>
                <ehcarts-map></ehcarts-map>
            </el-card>
        </el-row>

    </div>
</template>

<script>
    import CardBox from '@/components/HomeCard';
    import EchartsLine from '@/components/home/echartsLine'
    import EchartsHistogram from '@/components/home/EchartsHistogram'
    import EchartsHistogramColumn from '@/components/home/EchartsHistogramColumn'
    import EhcartsPie from '@/components/home/EhcartsPie'
    import EhcartsMap from '@/components/home/EhcartsMap'

    export default {
        name: "Index",
        components: {
            CardBox,
            EchartsLine,
            EchartsHistogram,
            EchartsHistogramColumn,
            EhcartsPie,
            EhcartsMap,
        },
        data() {
            return {
                info: [
                    {
                        type: "note",
                        title: "答题总数",
                        value: "150000"
                    },
                    {
                        type: "vip",
                        title: "会员总数",
                        value: "250"
                    },
                    {
                        type: "user",
                        title: "用户总数",
                        value: "150000"
                    },
                    {
                        type: "new_user",
                        title: "今日新增用户",
                        value: "150000"
                    },
                ],

                shortcutList: [
                    {
                        route: "/question_bank",
                        name: "题库管理"
                    },
                    {
                        route: "/question_bank",
                        name: "章节练习"
                    },
                    {
                        route: "/question_bank",
                        name: "用户管理"
                    },
                    {
                        route: "/question_bank",
                        name: "试卷组题"
                    },
                ],

                isShowAddShortcutDialog: false,

                //创建快捷方式表单
                shortcutForm: {
                    name: "",
                    router: ""
                },

                //快捷方式验证
                shortRule: {
                    name: [
                        {required: true, message: '请输入快捷方式名称', trigger: 'blur'},
                    ],
                    router: [
                        {required: true, message: '请输入路由', trigger: 'blur'},
                    ],
                },
                curTime: "",
                timeList: [
                    {
                        value: "1",
                        label: "今天",
                    },
                    {
                        value: "2",
                        label: "一周",
                    },
                    {
                        value: "3",
                        label: "一月",
                    },
                ]
            }
        },

        methods: {
            //新增快捷方式
            onAddShortcutClick() {
                this.resetForm();
                this.isShowAddShortcutDialog = true;
            },

            //新增快捷方式对话框取消
            onShortcutDialogCancel() {
                this.isShowAddShortcutDialog = false;
                this.resetForm();
            },

            //新增快捷方式对话框确定
            onShortcutDialogConfirm() {
                this.isShowAddShortcutDialog = false;
                //todo 测试
                let name = this.shortcutForm.name;
                let router = this.shortcutForm.router;
                if (!name && !router) {
                    return;
                }

                this.shortcutList.push(this.shortcutForm);
            },

            //重置表单
            resetForm() {
                this.$refs['shortcutForm'].resetFields();
            },

            //快捷方式跳转
            onJumpShortClick(route) {
                this.$router.push(route);
            },
        }
    }
</script>

<style lang="stylus" scoped>
    .card-box
        border 1px solid whitesmoke
        display flex
        flex-direction column
        justify-content center

    .el-select > .el-input
        width 100px
    .z-index-999
        z-index 999
</style>