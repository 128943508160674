<template>
    <div id="histogramColum" style="width:500px; height: 400px"></div>
</template>

<script>
    export default {
        name: "EchartsHistogramColumn",
        data(){
            return{

            }
        },

        mounted(){
            this.initHistogranColumn();
        },

        methods:{
            initHistogranColumn(){
                let echarts = require('echarts/lib/echarts');
                require('echarts/lib/chart/bar');
                // 以下的组件按需引入
                require('echarts/lib/component/tooltip');   // tooltip组件
                require('echarts/lib/component/title') ;  //  title组件
                require('echarts/lib/component/legend');

                let myChart = echarts.init(document.getElementById('histogramColum'));

               let option = {
                    color: ['#3398DB'],
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                            type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
                        }
                    },
                    grid: {
                        left: '3%',
                        right: '10%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: [
                        {
                            type: 'category',
                            data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                            axisTick: {
                                alignWithLabel: true
                            }
                        }
                    ],
                    yAxis: [
                        {
                            type: 'value'
                        }
                    ],
                    series: [
                        {
                            name: '直接访问',
                            type: 'bar',
                            barWidth: '60%',
                            data: [10, 52, 200, 334, 390, 330, 220]
                        }
                    ]
                };
                myChart.setOption(option);
            }
        }
    }
</script>

<style scoped>

</style>